import $ from 'jquery';
import DataConsent from '@qbus/data-consent'
import 'slick-carousel';
import { Dropdown, Modal } from 'bootstrap';

$(document).ready( function() {
    var currentPosition = 0;

    var navHeaderState = function(e) {
        currentPosition = document.scrollingElement.scrollTop;

        if (currentPosition > 10) {
            document.documentElement.classList.add('nav-header-reduce');
        } else {
            document.documentElement.classList.remove('nav-header-reduce');
        }
    };

    navHeaderState();
    document.addEventListener('scroll', navHeaderState);

    if (navigator.userAgent.match(/SamsungBrowser/i)) {
        document.documentElement.classList.add('ua-samsung-browser');
    }

    var notification = document.getElementById('data-consent-notification');

    var consent = new DataConsent({
        version: 2,
        banner: false,
        transatlanticServices: {
            functional: {
                "google-maps": {
                    label: 'Google-Maps',
                    group: 'Karten'
                },
            },
        },
        onClose: function (state) {
            notification && notification.classList.add('show');
        }
    });

    if (notification) {
        notification.querySelector('button.data-consent-edit').addEventListener('click', function() {
            notification.classList.remove('show')
            consent.forceOpen();
        });
        notification.querySelector('button.data-consent-close').addEventListener('click', function() {
            notification.classList.remove('show')
        });
    }

    consent.isAccepted().then(function(state) {
        var referrer_host = document.referrer.split('/')[2];
        if (window.location.host !== referrer_host && notification) {
            notification.classList.add('show');
        }
    });

    consent.isAccepted('functional').then(function(type) {
        $('iframe[data-src]').attr('src', function() {
            return $(this).attr('data-src');
        });
    });

    consent.isAccepted('functional:google-maps').then(function(type) {
        window.loadGmaps();
    });

    if ($('body').hasClass('data-consent-privacy-page') == false) {
        consent.launch();
    }

    /* For iframes or links that want to open the dialog */
    window.openConsent = function() {
        consent.forceOpen();
    };

    window.loadGmaps = function() {
        $.getScript(
            'https://maps.google.com/maps/api/js?key=AIzaSyDNI8qW1BHQ3KWChg83XDDmmps9IJtteh0&language=&sensor=false',
            function() {
                var gmap_init_funcs = window.gmap_init_funcs || [];
                $.each(gmap_init_funcs, function(index, func) {
                    window[func]();
                });
            }
        );
    }

    var windowHeight = $(window).height();
    var windowWidth = $(window).width();

    var diaryPosition = function() {
        currentPosition = document.scrollingElement.scrollTop;
        $('.news_latest--diary').each( function(i) {
            var offsetTop = $(this).offset().top;
            var centerTop = offsetTop + $(this).outerHeight()/2;
            var centerPos = centerTop - currentPosition;
            var offsetPos = offsetTop - currentPosition;
            var $imageContainer = $(this).find('.news_latest--diary__image');
            var $textContainer = $(this).find('.news_latest--diary__text');
            if (i%2 == 0) {
                var $leftContainer = $imageContainer;
                var $rightContainer = $textContainer;
            }
            else {
                var $leftContainer = $textContainer;
                var $rightContainer = $imageContainer;
            }
            if (centerPos > windowHeight) {
                $leftContainer.css('transform', 'translateX(-' + windowWidth/2 + 'px)');
                $rightContainer.css('transform', 'translateX(' + windowWidth/2 + 'px)');
            }
            else if (centerPos < windowHeight/2) {
                $leftContainer.css('transform', 'translateX(0px)');
                $rightContainer.css('transform', 'translateX(0px)');
            }
            else {
                var factor = (centerPos - windowHeight/2)/(windowHeight/2);
                $leftContainer.css('transform', 'translateX(-' + factor*windowWidth/2 + 'px)');
                $rightContainer.css('transform', 'translateX(' + factor*windowWidth/2 + 'px)');
            }
        });
    };

    diaryPosition();
    document.addEventListener('scroll', diaryPosition);
    window.addEventListener('resize', function() {
        windowHeight = $(window).height();
        windowWidth = $(window).width();
        diaryPosition();
    });

    $('.mod_newslist .modal').appendTo('body');

    var dropdownElList = [].slice.call(document.querySelectorAll('.ce_form .btn-select'));
    var dropdownList = dropdownElList.map(function (dropdownToggleEl) {
        var dropdown = new Dropdown(dropdownToggleEl);
        dropdownToggleEl.addEventListener('show.bs.dropdown', function () {
            var activeDropdownToggleEl = document.querySelector('.ce_form .btn-select.show');
            if (activeDropdownToggleEl) {
                Dropdown.getInstance(activeDropdownToggleEl).hide();
            }
        });

        return dropdown;
    });

    $('.ce_gallery ul').each( function() {
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: true,
            fade: true,
            draggable: true,
            adaptiveHeight: true,
            prevArrow: '<button type="button" class="slick-prev">Previous<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.704 6.35" height="24" width="14"><path  fill="currentColor" d="M3.143 297 0 293.825l3.143-3.175.561.566-2.582 2.609 2.582 2.608z" transform="translate(0 -290.65)"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next">Next<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.704 6.35" height="24" width="14"><path  fill="currentColor" d="M3.143 297 0 293.825l3.143-3.175.561.566-2.582 2.609 2.582 2.608z" transform="translate(0 -290.65)"/></svg></button>'
        });
    });

    $('.mod_newslist--slider').each( function() {
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            arrows: true,
            fade: false,
            draggable: false,
            adaptiveHeight: true,
            prevArrow: '<button type="button" class="slick-prev">Previous<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.704 6.35" height="24" width="14"><path  fill="currentColor" d="M3.143 297 0 293.825l3.143-3.175.561.566-2.582 2.609 2.582 2.608z" transform="translate(0 -290.65)"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next">Next<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.704 6.35" height="24" width="14"><path  fill="currentColor" d="M3.143 297 0 293.825l3.143-3.175.561.566-2.582 2.609 2.582 2.608z" transform="translate(0 -290.65)"/></svg></button>'
        });
    });
});
